// src/api.js

import axios from 'axios';

const instance = axios.create({
  baseURL: `https://scorlenticket-server.vercel.app/`, // L'URL del tuo backend
  //baseURL: `http://localhost:5001/`, // L'URL del tuo backend
  timeout: 5000 // Timeout per le richieste
});

export default instance;
