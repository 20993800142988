import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import SeatList from './SeatList';

const HomePage = () => {
    return (
        <ChakraProvider>
            <SeatList />
        </ChakraProvider>
    )
}

export default HomePage;
