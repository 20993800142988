import { Center, Flex, Button, Table, Tbody, IconButton, Td, Th, Text, Thead, Tr, Box, Heading } from "@chakra-ui/react";
import React from "react";
import { useBreakpointValue } from "@chakra-ui/react";
import { ImCross } from "react-icons/im";

const CarrelloPage = () => {
  const buttonSize = useBreakpointValue({ base: "sm", md: "sm" });
  const tableFontSize = useBreakpointValue({ base: "sm", md: "md" });
  const selectedSeats = [{row:"A", number:1, price:10}, {row:"B", number:2, price:10}, {row:"C", number:3, price:10}, {row:"D", number:4, price:10}, {row:"E", number:5, price:10}];
  const totalPrice = selectedSeats.reduce((acc, seat) => acc + seat.price, 0);
  const fee = 0.5 * selectedSeats.length;
  const totale = totalPrice + fee;
  const borderColor = "#ff007f"
  //const borderColor = "#0affd2"

  return (
    <Flex bgColor={"black"} height="1000px" justify={"center"} align={"center"}>
      <Center>
        <Flex direction="column" align="center">
          
          {/* Contorno lime con scritta "Carrello" inclusa all'interno del bordo */}
          <Box
            border="2px solid"
            borderColor={borderColor}
            borderRadius="md"
            p={4}
            width="100%"
            maxW="600px"
            position="relative"
          >
            {/* Scritta "Carrello" integrata dentro il contorno */}
            <Box
              position="absolute"
              top="-18px"  // Posiziona la scritta più in basso, sotto il bordo superiore
              left="50%"
              transform="translateX(-50%)"
              px={3}
              bg="black"  // Colore di sfondo nero per integrarlo nel layout generale
            >
              <Heading as="h3" size="lg" textTransform={"uppercase"} textAlign="center" color={borderColor}>
                Carrello
              </Heading>
            </Box>
            
            <Table variant="simple" color="gray.400" fontSize={tableFontSize} mt={10}>
              <Thead>
                <Tr>
                  <Th textAlign="center" color={borderColor}>Fila</Th>
                  <Th textAlign="center" color={borderColor}>Posto</Th>
                  <Th textAlign="center" color={borderColor}>Prezzo</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {selectedSeats.map(({ row, number, price }) => (
                  <Tr key={`${row}-${number}`}>
                    <Td textAlign="center">{row}</Td>
                    <Td textAlign="center">{number}</Td>
                    <Td textAlign="right">{price.toFixed(2).toString().replace(".", ",")} €</Td>
                    <Td textAlign="center">
                      <IconButton
                        colorScheme={borderColor}
                        _hover={{ color: "white", bgColor: borderColor }}
                        aria-label="Rimuovi"
                        size={buttonSize}
                        icon={<ImCross />}
                        // onClick={() => handleRemoveSeat(row, number)}
                      />
                    </Td>
                  </Tr>
                ))}
                <Tr>
                    <Td colSpan={4} borderBottom="none"></Td>
                </Tr>
                {/* Riga per il subtotale */}
                <Tr>
                  <Td colSpan={2} textAlign="left" fontWeight="bold" borderBottom="none">Subtotale:</Td> {/* Unire fila e posto */}
                  <Td textAlign="right" fontWeight="bold" borderBottom="none">{totalPrice.toFixed(2).toString().replace(".",",")} €</Td>
                  <Td borderBottom="none"></Td>
                </Tr>
                {/* Riga per le commissioni */}
                <Tr>
                  <Td colSpan={2} textAlign="left" fontWeight="bold" borderBottom="none">Commissioni Servizi:</Td> {/* Unire fila e posto */}
                  <Td textAlign="right" fontWeight="bold" borderBottom="none">{fee.toFixed(2).toString().replace(".",",")} €</Td>
                  <Td borderBottom="none"></Td>
                </Tr>
                {/* Riga per il totale */}
                <Tr>
                  <Td colSpan={2} textAlign="left" borderBottom="none" bgColor={borderColor} color="white" fontWeight={"bold"}>Totale:</Td> {/* Unire fila e posto */}
                  <Td textAlign="right" borderBottom="none" fontWeight="bold" bgColor={borderColor} color="white">{totale.toFixed(2).toString().replace(".",",")} €</Td>
                  <Td bgColor={borderColor} borderBottom="none"></Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>

          <Button colorScheme="whatsapp" borderRadius={"10px"} size={buttonSize} mt={8} p={4}>
            <Text textAlign={"center"} mt="3px">CONFERMA</Text>
          </Button>
        </Flex>
      </Center>
    </Flex>
  );
};

export default CarrelloPage;
