import { Flex, Text } from "@chakra-ui/react";
import React from "react";

function Screen({ width, height }: any) {
  return (
    <Flex
      bg="black"
      color="white"
      textAlign="center"
      width={width}
      height={height}
      borderRadius="md"
      border="1px solid white"
      boxShadow="lg"
      p={{base:0, md:4}}
      mb={{base: "30px", md:"50px"}}
      align={"center"}
      justify={"center"}
    >
      <Text textTransform={'uppercase'} fontSize={{base:"12px", md:"xl"}} fontWeight="bold">Palco</Text>
    </Flex>
  );
}

export default Screen;
