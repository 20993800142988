import { Box, Flex, Skeleton } from "@chakra-ui/react";
import React from "react";

export const HallSkeleton = () => (
    <Flex direction="column" h="auto">
      {[...Array(15)].map((_, index) => (
        <RowSkeleton key={index} />
      ))}
    </Flex>
  );

  const RowSkeleton = () => (
    <Flex justify="center">
      {[...Array(20)].map((_, index) => (
        <React.Fragment key={index}>
        <SeatSkeleton />
        {index === 9 && <Box marginLeft={{ base: "10px", md: "8vh" }}></Box>}
      </React.Fragment>
      ))}
    </Flex>
  );

  const SeatSkeleton = () => (
    <Skeleton m={{ base: 0.4, md: 2 }}
    bg="gray.200"
    borderRadius="md"
    width={{ base: "16px", md: "40px" }}
    height={{ base: "2vh", md: "4vh" }}>
    </Skeleton>
  );