import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import CarrelloPage from './CarrelloPage.tsx';

const HomePage = () => {
    return (
        <ChakraProvider>
            <CarrelloPage />
        </ChakraProvider>
    )
}

export default HomePage;
