import { disponibile, inPrenotazione, prenotato, vuoto } from './colors';

export function getColorFromStatus(seat) {
  const now = new Date();

  // Se la differenza tra lockedUntil e now è > 0, il posto sta venendo prenotato da qualcun altro
  if (seat.lockedUntil && new Date(seat.lockedUntil).getTime() - now > 0) {
    return prenotato;
  }

  switch (seat.stato) {
    case 'Disponibile':
      return disponibile;
    case 'In prenotazione':
        return inPrenotazione;
    case 'Prenotato':
      return prenotato;
    default:
      return vuoto;
  }
}

export function getHoverStyle(seat) {
  const now = new Date();
  if (seat.lockedUntil && new Date(seat.lockedUntil).getTime() - now > 0) {
    return prenotato;
  }

  if (seat.stato === 'Disponibile') {
    return { bg: 'gray.400' };
  }else{
    return { bg: getColorFromStatus(seat) };
  }
}