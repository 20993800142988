import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { Box, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Spinner, useDisclosure, ChakraProvider } from '@chakra-ui/react';

const CancelPage = () => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const { isOpen } = useDisclosure({ defaultIsOpen: true });
  const navigate = useNavigate();
  const server = 'https://scorlenticket-server.vercel.app';
  //const server = 'http://localhost:5001';

  useEffect(() => {
    const cancelBooking = async () => {
      const query = new URLSearchParams(window.location.search);
      const sessionId = query.get('session_id');
      
      if (!sessionId) {
        setMessage('ID sessione mancante');
        setLoading(false);
        setTimeout(() => navigate('/'), 5000);
        return;
      }

      try {
        const response = await fetch(`${server}/cancel-booking`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sessionId }),
        });

        if (response.ok) {
          setMessage('Prenotazione annullata');
          setLoading(false);
        } else {
          setMessage('Errore durante la cancellazione della prenotazione.');
          setLoading(false);
        }
      } catch (error) {
        console.error('Errore durante la cancellazione:', error);
        setMessage('Errore durante la cancellazione della prenotazione.');
        setLoading(false);
      }

      // Redirect alla homepage dopo qualche secondo
      setTimeout(() => navigate('/'), 5000);
    };
    
    cancelBooking();
  // eslint-disable-next-line
  }, [navigate]);

  return (
    <ChakraProvider>
      <Box height="100vh" bg="black" display="flex" justifyContent="center" alignItems="center">
        <Modal isOpen={isOpen} onClose={() => {}} isCentered>
          <ModalOverlay />
          <ModalContent bg="gray.800" color="white" textAlign="center" py={6}>
            <ModalHeader fontSize="2xl" fontWeight="bold" textTransform="uppercase">
              {loading ? 'Annullamento in corso...' : message}
            </ModalHeader>
            <ModalBody>
              {loading ? (
                <Spinner size="xl" />
              ) : (
                <Text fontSize="lg">
                  I posti verranno resi disponibili agli utenti. Verrai reindirizzato alla homepage tra 5 secondi.
                </Text>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </ChakraProvider>
  );
};

export default CancelPage;
