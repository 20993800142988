import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'; // Importa Provider
import store from './redux/store'; // Importa lo store Redux
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}> {/* Passa lo store Redux al Provider */}
    <App />
  </Provider>
);
