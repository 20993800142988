// src/redux/store.js

import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import seatReducer from './reducers/seatReducer';

// Combina tutti i riduttori se ce ne sono più di uno
const rootReducer = combineReducers({
  seat: seatReducer,
  // Aggiungi altri riduttori qui se necessario
});

// Crea lo store Redux
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
