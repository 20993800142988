import { Box, Flex, Text, useBreakpointValue, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from "@chakra-ui/react";
import React from "react";
import { disponibile, prenotato, inPrenotazione } from "./colors.js";

export default function Legenda() {
    return (
        <Accordion allowToggle defaultIndex={-1} width={{base:"100%", md:"40%"}} p={8}>
            <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Box flex="1" textAlign="center" textTransform={"uppercase"} fontSize={{base:"xs", md:"md"}} fontWeight={"bold"} color="white">
                            Legenda Colori
                        </Box>
                        <AccordionIcon color="white"/>
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <Flex justify="space-between" align="center" wrap="wrap" gap={{base:0, md:4}} width="100%">
                        <SeatButtonLegend color={disponibile} text="Disponibile" />
                        <SeatButtonLegend color={prenotato} text="Occupato" />
                        <SeatButtonLegend color={inPrenotazione} text="Selezionato" />
                    </Flex>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
}

function SeatButtonLegend({ color, text }: any) {
    const boxSize = useBreakpointValue({ base: "40px", md: "60px" });
    const textSize = useBreakpointValue({ base: "0.8rem", md: "md" });

    return (
        <Flex direction="column" align="center" width="100px" mb={{ base: 2, md: 4 }}>
            <Box bg={color} width={boxSize} height={boxSize} borderRadius="md" />
            <Text fontSize={textSize} color="white" mt={2} textAlign="center">{text}</Text>
        </Flex>
    );
}
