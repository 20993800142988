import React, { useEffect, useState } from 'react';
import { Button, Flex, Box, Text, Center, UnorderedList, ListItem, Heading } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { fetchSeats, bookSeat, sendEmail, updateSeat, checkAllSeatsAvailable } from '../redux/actions/seatAction';
import Screen from './Screen.tsx';
import Legenda from './Legenda.tsx';
import Carrello from './Carrello.tsx';
import { HallSkeleton } from './HallSkeleton.tsx';
import { getColorFromStatus, getHoverStyle } from './seatUtils.js'; // Importa le funzioni di utilità
//import { v4 as uuidv4 } from 'uuid';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@chakra-ui/react';
import Stripe from './Stripe.tsx';
import { borderColor } from './colors.js';

var timerId = -1;

const SeatList = ({ seats, cartSeats, fetchSeats, bookSeat, sendEmail, updateSeat, checkAllSeatsAvailable }) => {
  useEffect(() => {
    fetchSeats();
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timeoutId); // Pulizia dell'effetto
  }, [fetchSeats]);

  const [selectedSeats, setSelectedSeats] = useState([]);
  const [timer, setTimer] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  // eslint-disable-next-line
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAlreadyTaken, setIsAlreadyTaken] = useState(false);
  // TODO: Il primo è per il modal di benvenuto, il secondo per il modal di chiusura prenotazioni 
  const [firstModal, setFirstModal] = useState(false);
  const [closeBooking] = useState(true);
  const timeLimit = 600000; // 10 minuti
  // ****************************************************************************************** //
  const handleSeatClick = async (row, number) => {
    const seat = seats.find(seat => seat.fila === row && seat.numero === number);
    if (seat.stato === "Disponibile") {
      const price = seat.prezzo;
  
      // Aggiorna l'array dei posti selezionati mantenendo l'ordine per fila e numero
      const updatedSeats = [...selectedSeats, { row, number, price }].sort((a, b) => {
        if (a.row < b.row) return -1;
        if (a.row > b.row) return 1;
        return a.number - b.number;
      });
  
      setSelectedSeats(updatedSeats);
  
      seat.stato = "In prenotazione";
      // Se non c'è già un timer in esecuzione, avvialo
      if (timer === null) {
        const startTime = Date.now();
        const endTime = startTime + timeLimit; // Tempo limite: timeLimit minuti
        setTimer(0);
        timerId = setInterval(() => updateTimer(endTime), 1000); // Aggiorna il timer ogni secondo
        setTimeLeft(Math.ceil((endTime - Date.now()) / 1000)); // Calcola il tempo rimanente iniziale
      }
    }
  }

  // Funzione per aggiornare il timer
  const updateTimer = (endTime) => {
    const timeLeftInSeconds = Math.ceil((endTime - Date.now()) / 1000);
    setTimeLeft(timeLeftInSeconds);
    if (timeLeftInSeconds <= 0) {
      handleTimeout();
    }
  };

  // Funzione per gestire il timeout
  const handleTimeout = () => {
    clearInterval(timerId);
    setTimer(null);
    setIsModalOpen(false);
    setSelectedSeats([]);
    setTimeLeft(0);
    setTimeout(() => {
      fetchSeats();
    }, 1500);
    setTimeout(() => {
      fetchSeats();
    }, 500);
  };

  // Funzione per raggruppare i posti per fila
  const groupSeatsByRow = () => {
    const seatsByRow = {};
    seats.forEach(seat => {
      if (!seatsByRow[seat.fila]) {
        seatsByRow[seat.fila] = [];
      }
      seatsByRow[seat.fila].push(seat);
    });
    // Ordina i posti all'interno di ciascuna fila per numero
    Object.keys(seatsByRow).forEach(row => {
      seatsByRow[row].sort((a, b) => {
        const seatNumberA = parseInt(a.numero);
        const seatNumberB = parseInt(b.numero);
        return seatNumberA - seatNumberB;
      });
    });
    return seatsByRow;
  };

  // Funzione per controllare se un posto è presente nel carrello
  const isSeatInCart = (row, number) => {
    return cartSeats.some(seat => seat.fila === row && seat.numero === number);
  };

  const handleRemoveSeat = (row, number) => {
    const seat = seats.find(seat => seat.fila === row && seat.numero === number);
    
    if (seat) {
      seat.stato = "Disponibile";
      seat.lockedUntil = "1970-01-01T00:00:00.000+00:00"; // Reset del campo lockedUntil
  
      // Aggiorna il posto nel database
      updateSeat(row, number, "", "1970-01-01T00:00:00.000+00:00"); // SessionId vuoto e lockedUntil resettato
  
      // Rimuovi il posto dal carrello
      const updatedSelectedSeats = selectedSeats.filter(seat => !(seat.row === row && seat.number === number));
      setSelectedSeats(updatedSelectedSeats);
  
      // Se non ci sono più posti selezionati, resetta anche il timer
      if (selectedSeats.length === 1) {
        handleTimeout();
      }
    }
  };

  const seatsByRow = groupSeatsByRow();

  return (
    <Flex bg="black" direction="column" align={"center"} h="auto">
      <Center mt="20px">
        <Flex direction={"column"} align={"center"} maxWidth={{base:"400px", md:"900px"}} width="100%" px={4}>
          <Screen width={{base:"260px", md:"800px"}} height={{base:"30px", md:"100px"}}/>
          {timeLeft > 0 && <Text fontSize={{base:"8px", md:"20px"}} mb={{base:"20px", md:"30px"}} color="gray.400">Tempo rimanente per questa prenotazione: {timeLeft} secondi</Text>}
          {/* Modal di benvenuto con le istruzioni */}
          <Modal isOpen={firstModal} isCentered>
            <ModalOverlay />
            <ModalContent bg="gray.100" color="gray.900">
                <ModalHeader p={8}>
                    <Text textTransform={"uppercase"} textAlign={"center"}>Benvenuto</Text>
                    <Text  textTransform={"uppercase"} textAlign={"center"} fontWeight={"thin"} fontSize={{base:"sm", md:"md"}}>Istruzioni per la tua prenotazione</Text>
                </ModalHeader>
                <ModalBody textAlign={"left"}>
                    <UnorderedList>
                      <ListItem>Seleziona sulla piantina il posto che desideri</ListItem>
                      <ListItem>Controlla nella sezione "Carrello" e conferma la tua prenotazione</ListItem>
                      <ListItem>Compila tutti i campi richiesti e invia la prenotazione</ListItem>
                      <ListItem>Effettua il pagamento e attendi la conferma</ListItem>
                      <ListItem>Controlla la tua email per trovare il riepilogo della tua prenotazione</ListItem>
                    </UnorderedList>
                    <Heading p={4} color="red" textTransform="uppercase" textAlign={"center"} fontSize={{base:"md", md:"lg"}}>Attenzione</Heading>
                    <UnorderedList color="red">
                      <ListItem>Puoi eliminare i posti selezionati tramite la X presente nella sezione "Carrello"</ListItem>
                      <ListItem>Non è necessario stampare la prenotazione, è sufficiente esibirla dal tuo dispositivo</ListItem>
                    </UnorderedList>
                </ModalBody>
                <ModalFooter justifyContent={"center"}>
                  <Button bg={borderColor} color="white" textTransform={"uppercase"} mr={3} onClick={() => setFirstModal(false)}>
                    Chiudi
                  </Button>
                </ModalFooter>
            </ModalContent>
          </Modal>
          {/* Modal di chiusura prenotazioni */}
          <Modal isOpen={closeBooking} isCentered>
            <ModalOverlay />
            <ModalContent bg="gray.100" color="gray.900">
                <ModalHeader>
                    <Text textTransform={"uppercase"} textAlign={"center"}>PREVENDITE CHIUSE</Text>
                </ModalHeader>
                <ModalBody textAlign={"center"}>
                    <Text>Ci rivediamo al prossimo evento.
                      <br/>TI ASPETTIAMO!</Text>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
          </Modal>
          {/* Modal di errore posti già prenotati */}
          <Modal isOpen={isAlreadyTaken} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Text>Posto già prenotato</Text>
                </ModalHeader>
                <ModalBody>
                    I posti selezionati sono stati prenotati da un altro utente, ci scusiamo per l'inconveniente. Si prega di selezionare altri posti.
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="red" mr={3} onClick={() => setIsAlreadyTaken(false)}>
                    Chiudi
                  </Button>
                </ModalFooter>
            </ModalContent>
          </Modal>
          {/* Mostra i posti */}
          {isLoading && <HallSkeleton />}
          {!isLoading &&
          <Flex direction="column" h="auto">
          {Object.keys(seatsByRow)
          .sort() // Ordina le chiavi alfabeticamente
          .map(row => (
            <Flex key={row} justify={'center'}>
              {seatsByRow[row].map((seat, index) => {
                // Verifica se il posto è nel carrello
                const isInCart = isSeatInCart(seat.fila, seat.numero);
                if(isInCart) seat.stato = "Prenotato";
                
                return (
                  <React.Fragment key={seat._id}>
                    <Box m={{base: 0.4, md:2}}>
                      <Button
                        size={{base:"xxs", md:"xl"}}
                        w={{base:"16px", md:"40px"}}
                        fontSize={{base:"7px", md:"14px"}}
                        h={{base:"2vh", md:"4vh"}}
                        bg={getColorFromStatus(seat)}
                        disabled={seat.stato !== "Disponibile"}
                        _hover={getHoverStyle(seat)}
                        cursor={(seat.stato === "Disponibile") ? "pointer" : "not-allowed"}
                        onClick={() => {
                          // FIXME: Questo vale solo quando chiudi le prenotazioni
                          //if(true) return;
                          // FIXME: Rimuovi la riga sopra per far funzionare i posti 
                          handleSeatClick(seat.fila, seat.numero);
                        }}
                        textColor={seat.stato === "Vuoto" ? "transparent" : "default"}
                      >
                          <Box as="span" letterSpacing="0.05em">
                            {`${seat.fila}${seat.numero}`}
                          </Box>
                      </Button>
                    </Box>
                    {/* Aggiungi uno spazio vuoto dopo il posto 10 */}
                    {index === 9 && <Box marginLeft={{base: "10px", md:"8vh"}}></Box>}
                  </React.Fragment>
                );
              })}</Flex>
            ))}
          </Flex>
          }
        </Flex>
      </Center>
      <Legenda />        
      <Flex direction="column" mb="30px" alignItems="center">
        <Carrello selectedSeats={selectedSeats} handleRemoveSeat={handleRemoveSeat} />
        <Stripe seatIds={selectedSeats}/>
      </Flex>
    </Flex>
  );
};

const mapStateToProps = state => ({
  seats: state.seat.seats,
  cartSeats: state.seat.cartSeats
});

const mapDispatchToProps = {
  fetchSeats,
  bookSeat,
  sendEmail,
  updateSeat,
  checkAllSeatsAvailable
};

export default connect(mapStateToProps, mapDispatchToProps)(SeatList);
