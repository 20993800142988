/*import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { Box, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Spinner, useDisclosure, ChakraProvider } from '@chakra-ui/react';

const SuccessPage = () => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const { isOpen } = useDisclosure({ defaultIsOpen: true });
  const navigate = useNavigate();
  //const server = 'https://scorlenticket-server.vercel.app';
  const server = 'http://localhost:5001';
  
  useEffect(() => {
    const confirmBooking = async () => {
      const query = new URLSearchParams(window.location.search);
      const sessionId = query.get('session_id');
      
      if (!sessionId) {
        setMessage('ID sessione mancante');
        setLoading(false);
        setIsError(true);
        return;
      }

      try {
        const response = await fetch(`${server}/confirm-booking`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sessionId }),
        });

        if (response.ok) {
          const data = await response.json();
          setMessage('Prenotazione completata con successo');
          console.log(data);
          setLoading(false);

          // Invia email con i dati della prenotazione
          const emailResponse = await fetch(`${server}/send-email`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              to: data?.email,  // Email dell'utente
              seats: data?.seats,  // Dati sui posti prenotati
              name: data?.name,  // Nome dell'utente
              surname: data?.surname,  // Cognome dell'utente
            }),
          });

          if (emailResponse.ok) {
            console.log("Email inviata con successo!");
          } else {
            console.error("Errore durante l'invio dell'email.");
            setIsError(true);
          }
          setIsError(false);
          // Redirect alla pagina iniziale dopo qualche secondo
          setTimeout(() => navigate('/'), 5000);
        } else {
          setMessage('Errore nella conferma della prenotazione');
          setLoading(false);
          setIsError(true)
        }
      } catch (error) {
        console.error('Errore durante la conferma della prenotazione:', error);
        setMessage('Errore nella conferma della prenotazione');
        setLoading(false);
        setIsError(true);
      }
    }
    confirmBooking();
  // eslint-disable-next-line
  }, [navigate]);

  return (
    <ChakraProvider>
      <Box height="100vh" bg="black" display="flex" justifyContent="center" alignItems="center">
        <Modal isOpen={isOpen} onClose={() => {}} isCentered>
          <ModalOverlay />
          <ModalContent bg="gray.800" color="white" textAlign="center" py={6}>
            <ModalHeader fontSize="2xl" fontWeight="bold" textTransform="uppercase">
              {loading ? 'Conferma della prenotazione in corso...' : message}
            </ModalHeader>
            <ModalBody>
              {loading ? (
                <Spinner size="xl" />
              ) : isError ? (
                <Text fontSize="lg">
                  C'è stato un errore nella conferma della tua prenotazione. Si prega di riprovare.
                </Text>
              ) : (
                <Text fontSize="lg">
                  Troverai i biglietti nella tua email. Verrai reindirizzato alla homepage tra 5 secondi.
                </Text>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </ChakraProvider>
  );
};

export default SuccessPage;
*/

import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ChakraProvider } from '@chakra-ui/react';

const SuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);
  
  return (
    <ChakraProvider>
      <Box height="100vh" bg="black" display="flex" justifyContent="center" alignItems="center">
        <Modal isOpen={true} onClose={() => {}} isCentered>
          <ModalOverlay />
          <ModalContent bg="gray.800" color="white" textAlign="center" py={6}>
            <ModalHeader fontSize="2xl" fontWeight="bold" textTransform="uppercase">
              Prenotazione Completata
            </ModalHeader>
            <ModalBody>
              <Text fontSize="lg">
                Grazie per la tua prenotazione! Riceverai i dettagli via email.
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </ChakraProvider>
  );
};

export default SuccessPage;
