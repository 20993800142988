// src/redux/reducers/seatReducer.js

import { BOOK_SEAT_SUCCESS } from '../actions/types'; // Importa il tipo di azione
import { FETCH_SEATS_SUCCESS } from '../actions/types';

// Definisci lo stato iniziale
const initialState = {
  seats: [], // Array di posti
  cartSeats: [], // Array di posti nel carrello
};

// Riduttore per gestire le azioni relative ai posti
const seatReducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOK_SEAT_SUCCESS:
      // Aggiorna lo stato dopo aver prenotato un posto
      return {
        ...state,
        seats: state.seats.map(seat => {
          if (seat.row === action.payload.row && seat.number === action.payload.number) {
            return {
              ...seat,
              status: 'booked',
            };
          }
          return seat;
        }),
      };
    case FETCH_SEATS_SUCCESS:
      return {
        ...state,
        seats: action.payload.seats, // Aggiorna lo stato con i nuovi dati dei posti
        cartSeats: action.payload.cartSeats, // Aggiorna lo stato con i nuovi dati dei posti
      };
    default:
      return state;
  }
};

export default seatReducer;
